var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "google-map-loader" },
    [
      _vm.initialParamsIsSet
        ? [
            _vm._t("default", null, {
              google: _vm.google,
              map: _vm.map,
              infoWindow: _vm.infoWindow,
              mapMarkers: _vm.mapMarkers,
            }),
          ]
        : _vm._e(),
      _c("div", {
        ref: "googleMap",
        staticClass: "google-map focus:outline-none rounded border",
      }),
      _c(
        "spinner-overlay",
        { attrs: { color: "transparent", loading: _vm.uiState === "loading" } },
        [_vm._v(" Loading clinics.. ")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }