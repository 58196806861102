var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full max-w-full" },
    [
      _c("v-select", {
        staticClass: "autocomplete-input",
        attrs: {
          loading: _vm.uiState === "loading",
          placeholder: "Search for a clinic or place",
          "clear-search-on-select": false,
          options: _vm.clinicPredictions.concat(_vm.googlePredictions),
          label: "name",
        },
        on: { input: _vm.handleClick, search: _vm.handleInput },
        scopedSlots: _vm._u([
          {
            key: "option",
            fn: function (option) {
              return [
                _c(
                  "div",
                  { staticClass: "flex items-center space-x-1 " },
                  [
                    option.icon
                      ? _c("fv-icon", {
                          staticClass: "text-xs text-gray-800",
                          attrs: { icon: option.icon },
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(option.name))]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "no-options",
            fn: function (ref) {
              var search = ref.search
              var searching = ref.searching
              return [
                searching
                  ? [
                      _vm._v(" No results found for "),
                      _c("em", [_vm._v(_vm._s(search))]),
                      _vm._v(". "),
                    ]
                  : _c("em", { staticStyle: { opacity: "0.5" } }, [
                      _vm._v("Start typing to search"),
                    ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }