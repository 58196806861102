var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full space-y-1" },
    [
      _c("base-label", [_vm._v(_vm._s(_vm.$t("opening_hours")))]),
      _vm.clinic.opening_hours && _vm.clinic.opening_hours.length
        ? _c("div", [
            _c(
              "table",
              { staticClass: "mb-2 w-full" },
              _vm._l(_vm.clinic.opening_hours, function (openingHours, index) {
                return _c(
                  "tr",
                  {
                    key: openingHours.id,
                    staticClass: "flex justify-between border-b border-dotted",
                  },
                  [
                    _c("td", [_vm._v(_vm._s(_vm.weekdays[index]) + ":")]),
                    openingHours.closed
                      ? _c("td", { staticClass: "text-gray-700" }, [
                          _vm._v(" " + _vm._s(_vm.$t("closed")) + " "),
                        ])
                      : _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(openingHours.opens_at) +
                              " - " +
                              _vm._s(openingHours.closes_at) +
                              " "
                          ),
                        ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _c("p", [_vm._v(_vm._s(_vm.clinic.open || ""))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }