var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkbox" }, [
    _c("label", [
      _c("span", { staticClass: "text-gray-700" }, [
        _vm._v(" " + _vm._s(_vm.filter.label) + " "),
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.checked,
            expression: "checked",
          },
        ],
        attrs: { type: "checkbox" },
        domProps: {
          value: _vm.filter.id,
          checked: Array.isArray(_vm.checked)
            ? _vm._i(_vm.checked, _vm.filter.id) > -1
            : _vm.checked,
        },
        on: {
          change: [
            function ($event) {
              var $$a = _vm.checked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = _vm.filter.id,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.checked = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.checked = $$c
              }
            },
            _vm.emitFilterValue,
          ],
        },
      }),
      _c("span", { staticClass: "checkmark" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }