<template>
  <div class="space-y-1"> 
    <base-label>
      {{ title }}
    </base-label>
    <div
      v-for="category in servicesByCategory"
      :key="category.id"
    >
      <div>
        <strong>{{ category.name }}</strong>
      </div>
      <div
        v-for="service in category.services"
        :key="service"
        class="px-2 py-1 mt-1 mr-1 inline-block rounded bg-gray-50 border text-sm"
      >
        <b>{{ service }}</b>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    servicesByCategory: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="postcss">
.category-title {
  color: #454545;
}
.service-category {
  margin-bottom: 0.6em;
}
.services-container {
  margin-bottom: 1em;
}
</style>
