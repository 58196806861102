<template>
  <div class="w-full space-y-1">
    <base-label>{{ $t('opening_hours') }}</base-label>
    <div v-if=" clinic.opening_hours && clinic.opening_hours.length">
      <table class="mb-2 w-full">
        <tr
          v-for="(openingHours, index) in clinic.opening_hours"
          :key="openingHours.id"
          class="flex justify-between border-b border-dotted"
        >
          <td>{{ weekdays[index] }}:</td>
          <td
            v-if="openingHours.closed"
            class="text-gray-700"
          >
            {{ $t('closed') }}
          </td>
          <td v-else>
            {{ openingHours.opens_at }} - {{ openingHours.closes_at }}
          </td>
        </tr>
      </table>
    </div>
    <p>{{ clinic.open || '' }}</p>
  </div>
</template>

<script>
export default {
  props: {
    clinic: {
      type: Object,
      required: true,
    },
  },
  computed: {
    weekdays() {
      return [
        this.$i18n.tc('mon'),
        this.$i18n.tc('tue'),
        this.$i18n.tc('wed'),
        this.$i18n.tc('thu'),
        this.$i18n.tc('fri'),
        this.$i18n.tc('sat'),
        this.$i18n.tc('sun'),
      ];
    },
  },
};
</script>

<style scoped lang="postcss">
</style>
