var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "space-y-1" },
    [
      _c("base-label", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
      _vm._l(_vm.servicesByCategory, function (category) {
        return _c(
          "div",
          { key: category.id },
          [
            _c("div", [_c("strong", [_vm._v(_vm._s(category.name))])]),
            _vm._l(category.services, function (service) {
              return _c(
                "div",
                {
                  key: service,
                  staticClass:
                    "px-2 py-1 mt-1 mr-1 inline-block rounded bg-gray-50 border text-sm",
                },
                [_c("b", [_vm._v(_vm._s(service))])]
              )
            }),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }