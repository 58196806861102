<template>
  <div class="w-full max-w-full">
    <v-select
      class="autocomplete-input"
      :loading="uiState === 'loading'"
      :placeholder="'Search for a clinic or place'"
      :clear-search-on-select="false"
      :options="[
        ...clinicPredictions,
        ...googlePredictions
      ]"
      label="name"
      @input="handleClick"
      @search="handleInput"
    >
      <template v-slot:option="option">
        <div class="flex items-center space-x-1 ">
          <fv-icon
            v-if="option.icon"
            :icon="option.icon"
            class="text-xs text-gray-800"
          />
          <span>{{ option.name }}</span>
        </div>
      </template>

      <template v-slot:no-options="{ search, searching }">
        <template v-if="searching">
          No results found for <em>{{ search }}</em>.
        </template>
        <em
          v-else
          style="opacity: 0.5;"
        >Start typing to search</em>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { debounce } from 'lodash';
import selectedPin from '@/assets/map/pin-selected.png';
/* eslint-disable camelcase */
import large_clinic from '@/assets/map/pin-hospital.png';
import medium_sized_clinic from '@/assets/map/pin-clinic.png';
import home_visits_only from '@/assets/map/pin-home.png';
import { ClinicsApi } from '@/api';

const big_clinic = large_clinic;
const small_middle_sized = medium_sized_clinic;
const ambulatory_home_visit = home_visits_only;
const mobile = home_visits_only;

const pinImages = {
  large_clinic,
  medium_sized_clinic,
  home_visits_only,
  big_clinic,
  small_middle_sized,
  ambulatory_home_visit,
  mobile,
};

const getIconForMarker = marker => {
  return pinImages[marker.clinic_type.key];
};

export default {
  name: 'Autocomplete',
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    mapMarkers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      geocoderService: null,
      autocompleteService: null,
      autocompleteValue: '',
      googlePredictions: [],
      clinicPredictions: [],
      sessionToken: null,
      uiState: 'idle',
    };
  },
  computed: {
    ...mapState('clinicMap', ['filteredClinics', 'activeMarkerId']),
    ...mapGetters('clinicMap', { country: 'getCountry' }),
  },
  mounted() {
    this.autocompleteService = new this.google.maps.places.AutocompleteService();
    this.geocoderService = new this.google.maps.Geocoder();
  },
  methods: {
    ...mapActions('clinicMap', ['getClinic']),

    handleClick(listItem) {
      if (!listItem) {
        return null;
      }
      if (listItem.clinic_type) {
        return this.clickedClinic(listItem);
      }
      return this.clickedPlace(listItem);
    },

    handleInput(input) {
      // console.log(input);
      const valueTrimmed = input.trim().toLowerCase();
      if (!valueTrimmed) {
        this.clinicPredictions = [];
        this.googlePredictions = [];
        return;
      }

      this.searchClinics(valueTrimmed);

      if (!this.sessionToken) {
        this.sessionToken = new this.google.maps.places.AutocompleteSessionToken();
      }

      const iso31662 = this.country ? this.country.iso_3166_2 : 'se';
      const componentRestrictions = iso31662 ? { country: [iso31662] } : {};

      const request = {
        input: valueTrimmed,
        componentRestrictions,
        bounds: this.map.getBounds(),
        types: ['geocode'],
        sessionToken: this.sessionToken,
      };
      this.autocompleteService.getPlacePredictions(
        request,
        (googlePredictions, status) => {
          if (status === this.google.maps.places.PlacesServiceStatus.OK) {
            const googleResult = googlePredictions.map(prediction => {
              return {
                name: prediction.description,
                // icon: 'map-pin',
                ...prediction,
              };
            });
            this.googlePredictions = googleResult;
          } else {
            this.googlePredictions = [];
          }
        }
      );
    },
    geocodePlace(prediction) {
      const query = {};
      if (prediction.place_id) {
        query.placeId = prediction.place_id;
      } else if (prediction.adress) {
        query.address = prediction.adress;
      }

      return new Promise((resolve, reject) =>
        this.geocoderService.geocode(query, (geocoded, status) => {
          if (status === 'OK') {
            resolve(geocoded);
          } else {
            reject(status);
            console.log(
              `Geocode was not successful for the following reason: ${status}`
            );
          }
        })
      );
    },
    clickedClinic(clinic) {
      // Clear previous selection icon
      const { activeMarkerId } = this;
      if (activeMarkerId) {
        const previousMarker = this.mapMarkers.find(
          m => m.id === activeMarkerId
        );
        if (previousMarker) {
          previousMarker.setIcon({
            url: getIconForMarker(previousMarker),
          });
          previousMarker.setZIndex(previousMarker.index);
        }
      }

      this.getClinic(clinic.id);

      const { lat, lng } = clinic;
      const position = {
        lat: +lat,
        lng: +lng,
      };
      const mapMarker = this.mapMarkers.find(m => m.id === clinic.id);

      if (mapMarker) {
        mapMarker.setIcon({ url: selectedPin });
      }

      this.map.setZoom(12);
      this.map.panTo(position);
    },

    async clickedPlace(prediction) {
      const geocodedPlaces = await this.geocodePlace(prediction);
      const geocodedPlace =
        geocodedPlaces && geocodedPlaces.length ? geocodedPlaces[0] : null;
      if (
        geocodedPlace &&
        geocodedPlace.geometry &&
        geocodedPlace.geometry.location
      ) {
        this.sessionToken = null;
        const { bounds, viewport } = geocodedPlace.geometry;
        const padding = { left: 200 };
        // Use bounds when available, viewport is not as intuitive
        const centerBounds = bounds || viewport;
        this.map.fitBounds(centerBounds, padding);
      }
    },
    async searchMatchingClinics(input) {
      try {
        this.uiState = 'loading';
        // eslint-disable-next-line func-names
        const clinicsResult = await ClinicsApi.filterClinics({
          search: input,
          countryId: this.country.id,
        });

        this.clinicPredictions = clinicsResult
          .filter(clinic => {
            return (
              (clinic.name && clinic.name.toLowerCase().includes(input)) ||
              (clinic.municipality &&
                clinic.municipality.toLowerCase().includes(input)) ||
              (clinic.adress && clinic.adress.toLowerCase().includes(input))
            );
          })
          .map(clinic => {
            return {
              icon: 'clinics',
              ...clinic,
            };
          });
        this.uiState = 'idle';
      } catch (error) {
        this.$notify({ group: 'error', text: error.message });
        this.uiState = 'error';
      }
    },
    // eslint-disable-next-line func-names
    searchClinics: debounce(function(search) {
      if (search.length > 1) {
        this.clinicPredictions = [];
        this.searchMatchingClinics(search);
      } else {
        this.clinicPredictions = [];
      }
    }, 300),
  },
};
</script>
<style lang="postcss">
.autocomplete-input .vs__search::placeholder,
.autocomplete-input .vs__dropdown-toggle,
.autocomplete-input .vs__dropdown {
  @apply rounded bg-white;
}
</style>
