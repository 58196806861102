// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".map-container {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n.map-container-standalone {\n  width: 100%;\n  height: calc(100vh - 70px);\n}\n.page-title {\n  color: #4a5568;\n}\n.close-btn {\n  cursor: pointer;\n  position: absolute;\n  top: 1em;\n  right: 1em;\n  background: #f26178;\n  border-radius: 100%;\n  color: white;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: 2em;\n  height: 2em;\n  border: none;\n  z-index: 9;\n}\n.close-btn:hover {\n  background: #f04964;\n}", ""]);
// Exports
exports.locals = {
	"firstvetGreen": "#3bbfad",
	"firstvetPeach": "#f097a4",
	"firstvetIceBlue": "#f7f8fc"
};
module.exports = exports;
