var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bg-beigeLighter",
      class: _vm.standAlone ? "map-container-standalone" : "map-container",
    },
    [
      _c("google-map-loader", {
        attrs: { "append-ratings": _vm.appendRatings },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var google = ref.google
              var map = ref.map
              var mapMarkers = ref.mapMarkers
              return [
                _c("side-panel", {
                  attrs: {
                    google: google,
                    map: map,
                    "map-markers": mapMarkers,
                  },
                }),
                _vm.hasActiveAppointment || _vm.$route.name === "chat-room"
                  ? _c(
                      "button",
                      {
                        staticClass: "close-btn",
                        on: { click: _vm.onCloseMap },
                      },
                      [_c("fv-icon", { attrs: { icon: "delete" } })],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }