<template>
  <div
    class="bg-beigeLighter"
    :class="standAlone ? 'map-container-standalone' : 'map-container'"
  >
    <google-map-loader :append-ratings="appendRatings">
      <template slot-scope="{ google, map, mapMarkers }">
        <side-panel :google="google" :map="map" :map-markers="mapMarkers" />
        <button
          v-if="hasActiveAppointment || $route.name === 'chat-room'"
          class="close-btn"
          @click="onCloseMap"
        >
          <fv-icon icon="delete" />
        </button>
      </template>
    </google-map-loader>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import GoogleMapLoader from '@/components/clinic-map/GoogleMapLoader.vue';
import SidePanel from '@/components/clinic-map/SidePanel.vue';

export default {
  name: 'MapContainer',
  components: {
    SidePanel,
    GoogleMapLoader,
  },
  props: {
    country: {
      default: null,
      type: Number,
      required: false,
    },
    standAlone: {
      type: Boolean,
      default: false,
    },
    appendRatings: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAdminGlobal: null,
    };
  },
  computed: {
    ...mapState('appointment', ['appointment']),
    hasActiveAppointment() {
      const isInAppointmentRoom = this.$route.name === 'appointment';
      return !!Object.keys(this.appointment).length && isInAppointmentRoom;
    },
  },
  async mounted() {
    /* eslint-disable */
    const country = typeof countryId !== 'undefined' ? countryId : this.country;

    if (country) {
      this.setCountry(country);

      await this.fetchCountries();

      // Set countries again after have fetched coordinates
      this.setCountry(country);
    }
  },

  beforeDestroy() {
    this.setClinic(null);
  },

  methods: {
    ...mapMutations('clinicMap', ['displayClinicMap', 'setClinic']),
    ...mapActions('clinicMap', ['setCountry']),
    ...mapActions('clinicStore', ['fetchCountries']),
    onCloseMap() {
      this.displayClinicMap(false);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/_variables.scss';

.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.map-container-standalone {
  width: 100%;
  height: calc(100vh - 70px);
}
.page-title {
  color: #4a5568;
}
.close-btn {
  cursor: pointer;
  position: absolute;
  top: 1em;
  right: 1em;
  background: $firstvet-no-peach;
  border-radius: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  border: none;
  z-index: 9;

  &:hover {
    background: darken($firstvet-no-peach, 5%);
  }
}
</style>
