// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".google-map-loader {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  width: 100%;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  height: 100%;\n  min-height: 100%;\n}\n.google-map {\n  width: 100%;\n  height: 100%;\n}\n.map-spinner-container {\n  position: absolute;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  opacity: 0.3;\n  z-index: 1;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  background: rgba(157, 157, 157, 0.342);\n}\n.map-spinner {\n  width: 3rem;\n  height: 3rem;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.info-window {\n  text-align: center;\n}\n.info-window h6 {\n  font-size: 1.2em;\n  font-family: \"obviously-narrow\";\n  font-weight: 600;\n}\n.info-window .clinic-type {\n  margin: 0;\n  text-transform: uppercase;\n  font-size: 0.7rem;\n  font-weight: 600;\n  color: #777;\n}", ""]);
// Exports
exports.locals = {
	"firstvetGreen": "#3bbfad",
	"firstvetPeach": "#f097a4",
	"firstvetIceBlue": "#f7f8fc"
};
module.exports = exports;
