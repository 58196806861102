var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col space-y-2 ml-2 mb-2" },
    [
      _c("base-label", { staticClass: "title" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _c(
        "div",
        { staticClass: "flex flex-col space-y-1" },
        _vm._l(_vm.filters, function (filter) {
          return _c("checkbox", {
            key: filter.id,
            attrs: { "filter-type": _vm.filterType, filter: filter },
            on: { "toggle-filter": _vm.toggle },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }