<template>
  <div class="flex flex-col space-y-2">
    <autocomplete
      :google="google"
      :map="map"
      :map-markers="mapMarkers"
    />
    <base-button
      size="sm"
      @click="showFilters = !showFilters"
    >
      <div class="flex space-x-2 items-center">
        <span>{{ $t('filter') }}</span>
        <fv-icon
          class="text-gray-600"
          :icon="showFilters ? 'chevron-up' : 'chevron-down'"
          size="xs"
        />
      </div>
    </base-button>
    <div
      v-if="showFilters"
      class="flex flex-col space-y-2 bg-gray-50 p-2 rounded border"
    >
      <checkbox-wrapper
        :title="$t('availability')"
        filter-type="filterAvailability"
        :filters="availabilityOptions"
      />
      <checkbox-wrapper
        :title="$t('clinic_type')"
        filter-type="filterClinicType"
        :filters="clinicOptions"
      />
      <checkbox-wrapper
        :title="$t('animal_species')"
        filter-type="filterAnimals"
        :filters="animalOptions"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Autocomplete from './Autocomplete.vue';
import CheckboxWrapper from './CheckboxWrapper.vue';

export default {
  name: 'FilterContainer',
  components: { Autocomplete, CheckboxWrapper },
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    mapMarkers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      availabilityOptions: [
        {
          id: 'currentlyOpen',
          label: this.$i18n.tc('currently_open'),
          checked: false,
        },
        {
          id: 'onCall',
          label: this.$i18n.tc('on_call_options'),
          checked: false,
        },
      ],
      showFilters: true,
      selectedCountryId: null,
    };
  },
  computed: {
    ...mapState('clinicStore', [
      'animalTypeOptions',
      'clinicTypes',
      'countries',
    ]),
    ...mapState('clinicMap', ['filterCountry', 'selectedClinic']),
    animalOptions() {
      return this.animalTypeOptions.map(type => ({
        ...type,
        label: type.name,
        checked: false,
      }));
    },
    clinicOptions() {
      return this.clinicTypes.map(type => {
        return {
          ...type,
          label: type.name,
          checked: false,
        };
      });
    },
  },
  watch: {
    filterCountry: {
      handler(value) {
        this.selectedCountryId = value;
      },
      immediate: true,
    },
    selectedClinic(val) {
      if (val) {
        this.showFilters = false;
      }
    },
  },
  async mounted() {
    await Promise.all([
      this.fetchAnimalTypes(),
      this.fetchClinicTypes({ country_id: this.filterCountry }),
    ]);
  },
  methods: {
    ...mapActions('clinicStore', ['fetchAnimalTypes', 'fetchClinicTypes']),
    ...mapActions('clinicMap', ['setCountry', 'filterClinics']),
    toggleVisibility() {
      this.showFilters = !this.showFilters;
    },
    changeCountry() {
      this.setCountry(this.selectedCountryId);
      this.fetchClinicTypes({ country_id: this.selectedCountryId });
      this.filterClinics();
    },
  },
};
</script>
