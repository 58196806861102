<template>
  <div class="google-map-loader">
    <template v-if="initialParamsIsSet">
      <slot
        :google="google"
        :map="map"
        :infoWindow="infoWindow"
        :mapMarkers="mapMarkers"
      />
    </template>
    <div ref="googleMap" class="google-map focus:outline-none rounded border" />
    <spinner-overlay color="transparent" :loading="uiState === 'loading'">
      Loading clinics..
    </spinner-overlay>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { debounce } from 'lodash';
import MarkerClusterer from '@googlemaps/markerclustererplus';

import selectedPin from '@/assets/map/pin-selected.png';
/* eslint-disable camelcase */
import large_clinic from '@/assets/map/pin-hospital.png';
import medium_sized_clinic from '@/assets/map/pin-clinic.png';
import home_visits_only from '@/assets/map/pin-home.png';
import clinicInfoWindow from '@/components/clinic-map/ClinicInfoWindow';
import {
  controlOptions,
  markerClusterOptions,
} from '@/config/google-maps-options';
import gmapsInit from '@/utils/map/gmaps';
import { format, getDay, isWithinInterval } from 'date-fns';

const big_clinic = large_clinic;
const small_middle_sized = medium_sized_clinic;
const ambulatory_home_visit = home_visits_only;
const mobile = home_visits_only;

const pinImages = {
  large_clinic,
  medium_sized_clinic,
  home_visits_only,
  big_clinic,
  small_middle_sized,
  ambulatory_home_visit,
  mobile,
};

const getIconForMarker = marker => {
  if (!marker.clinic_type) {
    return pinImages.medium_sized_clinic;
  }
  return pinImages[marker.clinic_type.key];
};

export default {
  props: {
    appendRatings: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      google: null,
      map: null,
      infoWindow: null,
      mapMarkers: [],
      markerCluster: null,
      bounds: null,
      uiState: 'idle',
      clinics: [],
    };
  },
  computed: {
    ...mapState('clinicMap', [
      'loading',
      'filterCountry',
      'filterCountryCoordinates',
      'selectedClinic',
      'activeMarkerId',
      'filterAnimals',
      'filterClinicType',
      'filterCurrentlyOpen',
      'filterOnCall',
    ]),
    initialParamsIsSet() {
      return !!this.google && !!this.map && !!this.infoWindow;
    },
    filters() {
      return {
        animalTypeIds: this.filterAnimals,
        clinicTypeIds: this.filterClinicType,
        currentlyOpen: this.filterCurrentlyOpen,
        onCall: this.filterOnCall,
      };
    },
  },
  watch: {
    filterCountryCoordinates(center) {
      if (!this.map) {
        return;
      }
      this.map.setCenter(center);
      this.map.setZoom(5);
    },
    filters: {
      deep: true,
      handler() {
        this.filterMarkers();
      },
    },
  },
  async mounted() {
    this.$root.$i18n.locale = 'uk';
    try {
      this.uiState = 'loading';
      await this.initMap(this.$refs.googleMap);
      await this.setupClinics();
      this.uiState = 'idle';
    } catch (error) {
      this.uiState = 'idle';
      this.$notify({ group: 'error', text: 'Something went wrong' });
    }
  },
  beforeDestroy() {
    if (this.mapMarkers && this.mapMarkers.length) {
      this.mapMarkers.forEach(marker => {
        marker.setMap(null);
        this.google.maps.event.clearInstanceListeners(marker);
      });
    }

    if (this.markerCluster) {
      this.markerCluster.clearMarkers();
    }

    window.globalGmapDiv = this.map.getDiv();
  },
  methods: {
    ...mapActions('clinicMap', ['getClinics', 'getClinic']),
    ...mapMutations('clinicMap', ['setClinicsInView']),

    async setupClinics() {
      const params = {};

      // Only fetch clinics with coordinates set
      params.coordinatesSet = 1;

      if (this.appendRatings) {
        params.ratings = 1;
      }
      const clinics = await this.getClinics(params);
      this.clinics = clinics;
      this.setMarkers();
      return this.clinics;
    },

    async initMap(mapContainer) {
      this.google = await gmapsInit();

      const { filterCountryCoordinates: center } = this;

      const options = {
        ...controlOptions,
        ...{ center },
      };

      if (window.globalGmap) {
        mapContainer.appendChild(window.globalGmapDiv);
        this.map = window.globalGmap;
        this.map.setOptions(options);
      } else {
        this.map = new this.google.maps.Map(mapContainer, options);
        window.globalGmap = this.map;
      }

      this.infoWindow = new this.google.maps.InfoWindow({
        disableAutoPan: true,
      });

      this.bounds = this.map.getBounds();

      const getBounds = debounce(() => {
        this.bounds = this.map.getBounds();
        this.filterMarkers();
        return true;
      }, 400);

      this.map.addListener('bounds_changed', getBounds);
    },

    filterMarkers() {
      this.setClinicsInView([]);
      const isWithinBounds = marker => {
        return this.bounds.contains(marker.getPosition());
      };
      const acceptsAnimalType = marker => {
        const animalTypesFilter = this.filters.animalTypeIds;
        if (!animalTypesFilter.length) {
          return true;
        }
        return marker.animalTypeIds.some(typeId => {
          return animalTypesFilter.includes(typeId.id);
        });
      };
      const hasClinicType = marker => {
        const clinicTypeFilter = this.filters.clinicTypeIds;
        if (!clinicTypeFilter.length) {
          return true;
        }
        return clinicTypeFilter.includes(marker.clinic_type.id);
      };

      const isCurrentlyOpen = marker => {
        const shouldFilter = this.filters.currentlyOpen;
        const currentTime = new Date();
        const today = getDay(new Date());
        const { openingHours } = marker;

        if (!shouldFilter) {
          return true;
        }
        if (!openingHours) {
          return true;
        }

        const todaysHours = openingHours.find(
          oh => oh.weekday_with_sunday_at_zero === today
        );
        if (!todaysHours) {
          return true;
        }
        if (todaysHours.closed) {
          return false;
        }
        const currentDate = format(new Date(currentTime), 'yyyy-MM-dd');
        const openingTime = new Date(`${currentDate} ${todaysHours.opens_at}`);
        const closingTime = new Date(`${currentDate} ${todaysHours.closes_at}`);
        return isWithinInterval(new Date(currentTime), {
          start: openingTime,
          end: closingTime,
        });
      };

      const hasOnCallService = marker => {
        const shouldFilterOnCall = this.filters.onCall;
        if (!shouldFilterOnCall) {
          return true;
        }
        return !!marker.onCall;
      };

      const clinicsInView = [];
      this.mapMarkers.forEach(marker => {
        const isVisible = isWithinBounds(marker);
        if (!isVisible) {
          marker.setVisible(false);
        } else {
          const hasAnimalType = acceptsAnimalType(marker);
          const isOpen = isCurrentlyOpen(marker);
          const hasOnCall = hasOnCallService(marker);
          const show =
            hasAnimalType && hasClinicType(marker) && isOpen && hasOnCall;
          marker.setVisible(show);
          if (show) {
            clinicsInView.push(marker);
          }
        }
        if (this.appendRatings) {
          this.setClinicsInView(clinicsInView);
        }
      });
      if (this.markerCluster) {
        this.markerCluster.repaint();
      }
    },

    setMarkers() {
      if (!this.markerCluster) {
        this.markerCluster = new MarkerClusterer(
          this.map,
          [],
          markerClusterOptions
        );
      } else {
        this.markerCluster.clearMarkers();
      }

      this.mapMarkers.forEach(marker => {
        marker.setMap(null);
        this.google.maps.event.clearInstanceListeners(marker);
      });

      this.markerCluster.setIgnoreHidden(true);
      this.mapMarkers = this.clinics.map((clinic, i) => {
        const iconUrl =
          this.selectedClinic && this.selectedClinic.id === clinic.id
            ? selectedPin
            : getIconForMarker(clinic);

        const mapMarker = new this.google.maps.Marker({
          id: clinic.id,
          map: this.map,
          position: clinic.position,
          icon: iconUrl,
          animalTypeIds: clinic.animal_types,
          clinic_type: clinic.clinic_type,
          openInfoWindowOnMount: false,
          name: clinic.name,
          label: { text: clinic.name, color: 'transparent' },
          ratings: clinic.insurance_company_ratings,
          rating_price: clinic.rating_price,
          rating_quality: clinic.rating_quality,
          zIndex: i,
          index: i,
          openingHours: clinic.opening_hours,
          onCall: clinic.on_call,
        });

        mapMarker.addListener('mouseover', () => {
          this.infoWindow.setContent(clinicInfoWindow(clinic));
          this.infoWindow.open(this.map, mapMarker);
        });

        mapMarker.addListener('mouseout', () => {
          this.infoWindow.close();
        });

        mapMarker.addListener('click', () => {
          this.setActiveMarker(mapMarker);
        });

        this.markerCluster.addMarker(mapMarker);

        return mapMarker;
      });

      this.filterMarkers();
    },

    async setActiveMarker(mapMarker) {
      const { activeMarkerId } = this;
      if (activeMarkerId === mapMarker.id) return;
      mapMarker.setIcon({ url: selectedPin });
      mapMarker.setZIndex(this.google.maps.Marker.MAX_ZINDEX + 1);
      if (activeMarkerId) {
        const previousMarker = this.mapMarkers.find(
          m => m.id === activeMarkerId
        );
        if (previousMarker) {
          previousMarker.setIcon({
            url: getIconForMarker(previousMarker),
          });
          previousMarker.setZIndex(previousMarker.index);
        }
      }

      this.getClinic(mapMarker.id);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/_variables.scss';

.google-map-loader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 100%;
  min-height: 100%;
}
.google-map {
  width: 100%;
  height: 100%;
}
.map-spinner-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(157, 157, 157, 0.342);
}
.map-spinner {
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-window {
  text-align: center;
  h6 {
    font-size: 1.2em;
    font-family: 'obviously-narrow';
    font-weight: 600;
  }
  .clinic-type {
    margin: 0;
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: 600;
    color: $gray;
  }
}
</style>
