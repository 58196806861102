<template>
  <div
    v-if="selectedClinic"
    class="px-2"
  >
    <spinner-overlay
      :loading="clinicIsLoading"
      size="xl"
    />

    <div class="pb-4 pt-2">
      <h3 class="font-black text-xl font-display tracking-wide">
        {{ selectedClinic.name }}
      </h3>
      <p
        class="text-sm text-gray-800"
      >
        {{ selectedClinic.clinic_type.name }}
      </p>
    </div>
    <div class="pb-2 mb-4 border-b">
      <text-button
        id="copyButton"
        type="button"
        class=""
        @click="copyInfo"
      >
        <span class="mr-auto text-sm">
          {{ $t(copiedInfo ? 'copied_to_clipboard' : (copyOnlyName ? 'copy_clinic_name' : 'copy_contact_info')) }}
        </span>
      </text-button>
    </div>

    <div class="flex flex-col space-y-5">
      <div class="space-y-1">
        <base-label>{{ $t('address') }}</base-label>
        <div class="">
          {{ selectedClinic.adress }}<br>
          <span v-if="selectedClinic.postcode">{{ selectedClinic.postcode }} </span>
          <span v-if="selectedClinic.city">{{ selectedClinic.city }}<br></span>
          {{ selectedClinic.municipality }}<br>
          {{ selectedClinic.country ? selectedClinic.country.name : '' }}
        </div>
      </div>

      <div class="space-y-1">
        <base-label>{{ $t('phone_number') }}</base-label>
        <div class="">
          <div v-if="selectedClinic.phone">
            <p>{{ $t('public') }}: {{ selectedClinic.phone }}</p>
          </div>
          <div v-if="selectedClinic.referral_phone">
            <p>{{ $t('referral_hidden') }}: {{ selectedClinic.referral_phone }}</p>
          </div>
        </div>
      </div>

      <div class="space-y-1">
        <base-label>
          {{ $t('email') }}
        </base-label>
        <div class="flex flex-col space-y-2">
          <div
            v-if="selectedClinic.email"
            class="break-words"
          >
            <p class="text-sm">
              {{ $t('public') }}:
            </p> <p class="text-primary-darker">
              {{ selectedClinic.email }}
            </p>
          </div>
          <div
            v-if="selectedClinic.referral_email"
            class="break-words"
          >
            <p class="text-sm">
              {{ $t('referral_hidden') }}:
            </p> <p class="text-primary-darker">
              {{ selectedClinic.referral_email }}
            </p>
          </div>
        </div>
      </div>
  

      <opening-hours
        :clinic="selectedClinic"
      />

      <general-info
        v-if="selectedClinic.emergency_care_options"
        :title="$t('emergency_care')"
        :text="selectedClinic.emergency_care_options.name"
      />

      <general-info
        v-if="selectedClinic.on_call !== null"
        :title="$t('on_call_options')"
        :text="selectedClinic.on_call ? $t('yes') : $t('no')"
      />

      <div
        v-if="selectedClinic.stationary_care_for_pets || selectedClinic.stationary_care_for_horses"
        class="space-y-1"
      >
        <base-label>{{ $t('inpatient_care') }}</base-label>
        <div v-if="selectedClinic.stationary_care_for_pets">
          <b>{{ $t('small_animals') }}:</b> {{ selectedClinic.stationary_care_for_pets.name }}
        </div>
        <div v-if="selectedClinic.stationary_care_for_horses">
          <b>{{ $t('horses') }}:</b> {{ selectedClinic.stationary_care_for_horses.name }}
        </div>
      </div>



      <general-info
        v-if="selectedClinic.referral_info"
        :title="$t('referral_info')"
        :text="selectedClinic.referral_info"
      />

      <div class="flex flex-col space-y-1">
        <base-label>{{ $t('animal_species') }}</base-label>
        <div class="flex flex-wrap space-y-1">
          <div
            v-for="type in selectedClinic.animal_types"
            :key="type.id"
            class="px-2 py-1 mt-1 mr-1 inline-block rounded bg-gray-50 border text-sm"
          >
            <strong>{{ type.name }}</strong>
          </div>
        </div>
      </div>

      <general-info
        v-if="selectedClinic.info"
        :title="$t('general_information')"
        :text="selectedClinic.info"
      />

      <clinic-services
        v-if="selectedClinic.pets.length"
        :title="$t('small_animals')"
        :services-by-category="selectedClinic.pets"
      />
      <clinic-services
        v-if="selectedClinic.horses.length"
        :title="$t('horses')"
        :services-by-category="selectedClinic.horses"
      />
      <clinic-services
        v-if="selectedClinic.horses_field.length"
        :title="$t('horses_field')"
        :services-by-category="selectedClinic.horses_field"
      />
      <clinic-services
        v-if="selectedClinic.farm_animals.length"
        :title="$t('agricultural_animals')"
        :services-by-category="selectedClinic.farm_animals"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import OpeningHours from './OpeningHours.vue';
import GeneralInfo from './GeneralInfo.vue';
import ClinicServices from './ClinicServices.vue';

export default {
  components: { OpeningHours, GeneralInfo, ClinicServices },
  data() {
    return {
      copiedInfo: false,
      copyOnlyName:
        window.location.search &&
        window.location.search.indexOf('vetpanel') > -1,
    };
  },
  computed: {
    ...mapState('clinicMap', ['selectedClinic', 'clinicIsLoading']),
  },
  methods: {
    copyInfo() {
      const { name, adress, phone } = this.selectedClinic;

      const city = this.selectedClinic.city || '';
      const postcode = this.selectedClinic.postcode || '';

      const contactInfo = this.copyOnlyName
        ? name
        : `${name}\n${this.$t(
            'address'
          )}: ${adress} ${postcode} ${city}\n${this.$t(
            'phone_number'
          )}: ${phone}`;
      const el = document.createElement('textarea');
      el.value = contactInfo;
      const copyButton = document.getElementById('copyButton');
      copyButton.insertBefore(el, copyButton.childNodes[0]);
      el.focus();
      el.select();
      document.execCommand('copy');
      copyButton.removeChild(el);

      this.copiedInfo = true;
      setTimeout(() => {
        this.copiedInfo = false;
      }, 1000);
    },
  },
};
</script>
