<template>
  <div class="overflow-auto px-3 pb-3 pt-0" style="width: 28em">
    <div class="flex max-h-full max-w-full flex-col space-y-2 pb-6">
      <filter-container :google="google" :map="map" :map-markers="mapMarkers" />
      <div
        v-if="selectedClinic"
        class="relative flex flex-col rounded border bg-white py-2"
      >
        <div class="px-2">
          <button class="mr-auto" @click="setClinic(null)">
            <fv-icon icon="close" size="xs" />
          </button>
        </div>

        <ClinicDetail />

        <div v-if="hasActiveAppointment" class="mt-6 w-full px-2">
          <base-button
            id="referralButton"
            color="primary"
            type="button"
            class="w-full"
            @click="displayClinicMap(false)"
          >
            {{ $t('select_for_referral') }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import FilterContainer from './FilterContainer.vue';
import ClinicDetail from './ClinicDetail/ClinicDetail.vue';

export default {
  components: {
    FilterContainer,
    ClinicDetail,
  },
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    mapMarkers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('clinicMap', ['selectedClinic']),
    ...mapState('appointment', ['appointment']),
    hasActiveAppointment() {
      return !!this.appointment?.id;
    },
  },
  methods: {
    ...mapMutations('clinicMap', ['displayClinicMap', 'setClinic']),
  },
};
</script>
