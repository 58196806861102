const infoWindow = marker => {
  return `
    <div class="info-window">
      <h6>${marker.name}</h6>
      <p class="clinic-type">${marker.clinic_type.name}</p>
    </div>
  `;
};

export default infoWindow;
