import m1 from '@/assets/map/clusters/m1.png';
import m2 from '@/assets/map/clusters/m2.png';
import m3 from '@/assets/map/clusters/m3.png';
import m4 from '@/assets/map/clusters/m4.png';
import m5 from '@/assets/map/clusters/m5.png';

const styles = [
  { elementType: 'labels.text.fill', stylers: [{ color: '#666666' }] },
  {
    featureType: 'all',
    elementType: 'geometry.fill',
    stylers: [
      {
        weight: '2.00',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#9c9c9c',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#333333',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#e2f2f3',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#e2f2f3',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#070707',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
];

const controlOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  gestureHandling: 'greedy',
  zoom: 5,
  center: {
    lat: 63,
    lng: 17,
  },
  styles,
};

const markerClusterOptions = {
  maxZoom: 9,
  minimumClusterSize: 3,
  gridSize: 40,
  zoomOnClick: false,
  ignoreHidden: true,
  styles: [
    {
      height: 45,
      url: m1,
      width: 45,
      className: 'font-black text-xs items-center text-center',
    },
    {
      height: 54,
      url: m2,
      width: 54,
      className: 'font-black text-xs items-center text-center',
    },
    {
      height: 66,
      url: m3,
      width: 66,
      className: 'font-black text-xs items-center text-center',
    },
    {
      height: 79,
      url: m4,
      width: 79,
      className: 'font-black text-xs items-center text-center',
    },
    {
      height: 94,
      url: m5,
      width: 94,
      className: 'font-black text-xs items-center text-center',
    },
  ],
};

export { controlOptions, markerClusterOptions };
