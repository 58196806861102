var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "overflow-auto px-3 pb-3 pt-0",
      staticStyle: { width: "28em" },
    },
    [
      _c(
        "div",
        { staticClass: "flex max-h-full max-w-full flex-col space-y-2 pb-6" },
        [
          _c("filter-container", {
            attrs: {
              google: _vm.google,
              map: _vm.map,
              "map-markers": _vm.mapMarkers,
            },
          }),
          _vm.selectedClinic
            ? _c(
                "div",
                {
                  staticClass:
                    "relative flex flex-col rounded border bg-white py-2",
                },
                [
                  _c("div", { staticClass: "px-2" }, [
                    _c(
                      "button",
                      {
                        staticClass: "mr-auto",
                        on: {
                          click: function ($event) {
                            return _vm.setClinic(null)
                          },
                        },
                      },
                      [_c("fv-icon", { attrs: { icon: "close", size: "xs" } })],
                      1
                    ),
                  ]),
                  _c("ClinicDetail"),
                  _vm.hasActiveAppointment
                    ? _c(
                        "div",
                        { staticClass: "mt-6 w-full px-2" },
                        [
                          _c(
                            "base-button",
                            {
                              staticClass: "w-full",
                              attrs: {
                                id: "referralButton",
                                color: "primary",
                                type: "button",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.displayClinicMap(false)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("select_for_referral")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }