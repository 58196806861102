<template>
  <div class="checkbox">
    <label>
      <span class="text-gray-700">
        {{ filter.label }}
      </span>
      <input
        v-model="checked"
        type="checkbox"
        :value="filter.id"
        @change="emitFilterValue"
      >
      <span class="checkmark" />
    </label>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Checkbox',
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filterType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  computed: {
    ...mapState('clinicMap', [
      'loading',
      'filterAnimals',
      'filterClinicType',
      'filterCurrentlyOpen',
      'filterOnCall',
    ]),
  },
  watch: {
    filterType() {
      this.checked = this.getCheckedStateFromStore();
    },
  },
  mounted() {
    this.checked = this.getCheckedStateFromStore();
  },
  methods: {
    emitFilterValue() {
      this.$emit('toggle-filter', {
        checked: this.checked,
        filterType: this.filterType,
        filterId: this.filter.id,
      });
    },
    getCheckedStateFromStore() {
      if (this.filterType === 'filterAvailability') {
        return this.filter.id === 'currentlyOpen'
          ? this.filterCurrentlyOpen
          : this.filterOnCall;
      }
      const checkedFilters = this[this.filterType];

      return checkedFilters ? checkedFilters.includes(this.filter.id) : null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/_variables.scss';

// .checkbox {
//   width: 50%;
// }

label {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 8px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  line-height: 20px;
  margin-right: 2em;

  /* Hide the browser's default checkbox */
  input {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    /* When the checkbox is checked, add a blue background */
    &:checked ~ .checkmark {
      /* Show the checkmark when checked */
      &:after {
        display: block;
      }
    }
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $background-gray;
    /* Create the checkmark/indicator (hidden when not checked) */
    &:after {
      content: '';
      position: absolute;
      display: none;
    }
    /* Style the checkmark/indicator */
    &:after {
      left: 6px;
      top: 1px;
      width: 8px;
      height: 15px;
      border: solid $brand-secondary;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
}
</style>
