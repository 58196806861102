var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedClinic
    ? _c(
        "div",
        { staticClass: "px-2" },
        [
          _c("spinner-overlay", {
            attrs: { loading: _vm.clinicIsLoading, size: "xl" },
          }),
          _c("div", { staticClass: "pb-4 pt-2" }, [
            _c(
              "h3",
              { staticClass: "font-black text-xl font-display tracking-wide" },
              [_vm._v(" " + _vm._s(_vm.selectedClinic.name) + " ")]
            ),
            _c("p", { staticClass: "text-sm text-gray-800" }, [
              _vm._v(" " + _vm._s(_vm.selectedClinic.clinic_type.name) + " "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "pb-2 mb-4 border-b" },
            [
              _c(
                "text-button",
                {
                  attrs: { id: "copyButton", type: "button" },
                  on: { click: _vm.copyInfo },
                },
                [
                  _c("span", { staticClass: "mr-auto text-sm" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            _vm.copiedInfo
                              ? "copied_to_clipboard"
                              : _vm.copyOnlyName
                              ? "copy_clinic_name"
                              : "copy_contact_info"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex flex-col space-y-5" },
            [
              _c(
                "div",
                { staticClass: "space-y-1" },
                [
                  _c("base-label", [_vm._v(_vm._s(_vm.$t("address")))]),
                  _c("div", {}, [
                    _vm._v(" " + _vm._s(_vm.selectedClinic.adress)),
                    _c("br"),
                    _vm.selectedClinic.postcode
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.selectedClinic.postcode) + " "),
                        ])
                      : _vm._e(),
                    _vm.selectedClinic.city
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.selectedClinic.city)),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.selectedClinic.municipality)),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.selectedClinic.country
                            ? _vm.selectedClinic.country.name
                            : ""
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "space-y-1" },
                [
                  _c("base-label", [_vm._v(_vm._s(_vm.$t("phone_number")))]),
                  _c("div", {}, [
                    _vm.selectedClinic.phone
                      ? _c("div", [
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("public")) +
                                ": " +
                                _vm._s(_vm.selectedClinic.phone)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.selectedClinic.referral_phone
                      ? _c("div", [
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("referral_hidden")) +
                                ": " +
                                _vm._s(_vm.selectedClinic.referral_phone)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "space-y-1" },
                [
                  _c("base-label", [
                    _vm._v(" " + _vm._s(_vm.$t("email")) + " "),
                  ]),
                  _c("div", { staticClass: "flex flex-col space-y-2" }, [
                    _vm.selectedClinic.email
                      ? _c("div", { staticClass: "break-words" }, [
                          _c("p", { staticClass: "text-sm" }, [
                            _vm._v(" " + _vm._s(_vm.$t("public")) + ": "),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "text-primary-darker" }, [
                            _vm._v(
                              " " + _vm._s(_vm.selectedClinic.email) + " "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.selectedClinic.referral_email
                      ? _c("div", { staticClass: "break-words" }, [
                          _c("p", { staticClass: "text-sm" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("referral_hidden")) + ": "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "text-primary-darker" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.selectedClinic.referral_email) +
                                " "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c("opening-hours", { attrs: { clinic: _vm.selectedClinic } }),
              _vm.selectedClinic.emergency_care_options
                ? _c("general-info", {
                    attrs: {
                      title: _vm.$t("emergency_care"),
                      text: _vm.selectedClinic.emergency_care_options.name,
                    },
                  })
                : _vm._e(),
              _vm.selectedClinic.on_call !== null
                ? _c("general-info", {
                    attrs: {
                      title: _vm.$t("on_call_options"),
                      text: _vm.selectedClinic.on_call
                        ? _vm.$t("yes")
                        : _vm.$t("no"),
                    },
                  })
                : _vm._e(),
              _vm.selectedClinic.stationary_care_for_pets ||
              _vm.selectedClinic.stationary_care_for_horses
                ? _c(
                    "div",
                    { staticClass: "space-y-1" },
                    [
                      _c("base-label", [
                        _vm._v(_vm._s(_vm.$t("inpatient_care"))),
                      ]),
                      _vm.selectedClinic.stationary_care_for_pets
                        ? _c("div", [
                            _c("b", [
                              _vm._v(_vm._s(_vm.$t("small_animals")) + ":"),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.selectedClinic.stationary_care_for_pets
                                    .name
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.selectedClinic.stationary_care_for_horses
                        ? _c("div", [
                            _c("b", [_vm._v(_vm._s(_vm.$t("horses")) + ":")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.selectedClinic.stationary_care_for_horses
                                    .name
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedClinic.referral_info
                ? _c("general-info", {
                    attrs: {
                      title: _vm.$t("referral_info"),
                      text: _vm.selectedClinic.referral_info,
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "flex flex-col space-y-1" },
                [
                  _c("base-label", [_vm._v(_vm._s(_vm.$t("animal_species")))]),
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap space-y-1" },
                    _vm._l(_vm.selectedClinic.animal_types, function (type) {
                      return _c(
                        "div",
                        {
                          key: type.id,
                          staticClass:
                            "px-2 py-1 mt-1 mr-1 inline-block rounded bg-gray-50 border text-sm",
                        },
                        [_c("strong", [_vm._v(_vm._s(type.name))])]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _vm.selectedClinic.info
                ? _c("general-info", {
                    attrs: {
                      title: _vm.$t("general_information"),
                      text: _vm.selectedClinic.info,
                    },
                  })
                : _vm._e(),
              _vm.selectedClinic.pets.length
                ? _c("clinic-services", {
                    attrs: {
                      title: _vm.$t("small_animals"),
                      "services-by-category": _vm.selectedClinic.pets,
                    },
                  })
                : _vm._e(),
              _vm.selectedClinic.horses.length
                ? _c("clinic-services", {
                    attrs: {
                      title: _vm.$t("horses"),
                      "services-by-category": _vm.selectedClinic.horses,
                    },
                  })
                : _vm._e(),
              _vm.selectedClinic.horses_field.length
                ? _c("clinic-services", {
                    attrs: {
                      title: _vm.$t("horses_field"),
                      "services-by-category": _vm.selectedClinic.horses_field,
                    },
                  })
                : _vm._e(),
              _vm.selectedClinic.farm_animals.length
                ? _c("clinic-services", {
                    attrs: {
                      title: _vm.$t("agricultural_animals"),
                      "services-by-category": _vm.selectedClinic.farm_animals,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }