var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col space-y-2" },
    [
      _c("autocomplete", {
        attrs: {
          google: _vm.google,
          map: _vm.map,
          "map-markers": _vm.mapMarkers,
        },
      }),
      _c(
        "base-button",
        {
          attrs: { size: "sm" },
          on: {
            click: function ($event) {
              _vm.showFilters = !_vm.showFilters
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex space-x-2 items-center" },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("filter")))]),
              _c("fv-icon", {
                staticClass: "text-gray-600",
                attrs: {
                  icon: _vm.showFilters ? "chevron-up" : "chevron-down",
                  size: "xs",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.showFilters
        ? _c(
            "div",
            {
              staticClass:
                "flex flex-col space-y-2 bg-gray-50 p-2 rounded border",
            },
            [
              _c("checkbox-wrapper", {
                attrs: {
                  title: _vm.$t("availability"),
                  "filter-type": "filterAvailability",
                  filters: _vm.availabilityOptions,
                },
              }),
              _c("checkbox-wrapper", {
                attrs: {
                  title: _vm.$t("clinic_type"),
                  "filter-type": "filterClinicType",
                  filters: _vm.clinicOptions,
                },
              }),
              _c("checkbox-wrapper", {
                attrs: {
                  title: _vm.$t("animal_species"),
                  "filter-type": "filterAnimals",
                  filters: _vm.animalOptions,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }