<template>
  <div class="space-y-1">
    <base-label>{{ title }}</base-label>
    <p>{{ text }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
