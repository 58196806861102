<template>
  <div class="flex flex-col space-y-2 ml-2 mb-2">
    <base-label class="title">
      {{ title }}
    </base-label>
    <div
      class="flex flex-col space-y-1"
    >
      <checkbox
        v-for="filter in filters"
        :key="filter.id"
        :filter-type="filterType"
        :filter="filter"
        @toggle-filter="toggle"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Checkbox from './Checkbox.vue';

export default {
  components: { Checkbox },

  props: {
    title: {
      type: String,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
    filterType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    ...mapActions('clinicMap', ['toggleFilter']),
    toggle({ filterId, checked, filterType }) {
      this.toggleFilter({
        id: filterId,
        checked,
        filterType,
      });
    },
  },
};
</script>
